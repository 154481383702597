<template>
  <main class="page">
    <section class="section section-portfolio pb-15">
      <div class="container container-heading container-404 text-center">
        <div class="row">
          <div class="col-12">
            <h2>Page not found!</h2>
            <p>It looks like nothing was found at this location.</p>
            <router-link
              :to="{
                path: '/',
              }"
              class="btn btn-primary"
              >Homepage</router-link>

              <router-link
              :to="{
                path: '/portfolio',
              }"
              class="btn btn-secondary"
              >Our Clients</router-link>
          </div>
        </div>
      </div>
    </section>
    <SectionCTA />
  </main>
</template>

<script>
// @ is an alias to /src
import SectionCTA from '@/components/SectionCTA.vue'
import { inject } from 'vue'

export default {
  name: 'Home',
  components: {
    SectionCTA
  },
  setup () {
    const headerClasses = inject('headerClasses')
    headerClasses.value = ''

    const filterMenuSpacing = inject('filterMenuSpacing')
    filterMenuSpacing.value = 'mb-5'

    // Reset filter menu
    const menu = inject('menu')
    for (const parentCat in menu) {
      for (const childCat in menu[parentCat]) {
        menu[parentCat][childCat] = false
      }
    }

    // Close menu
    const openMenuItem = document.querySelector(
      '.accordion-button[aria-expanded="true"]'
    )
    if (openMenuItem) openMenuItem.click()
  }
}
</script>